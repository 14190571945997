<template>
</template>

<script>
import global from "@/common/global"
export default{
	data(){
		return {
			suiteTicket:"",
			suiteAccessToken:"",
			code:"",
			isPc:false,			
		}
	},
	created() {		
		//判断终端类型
		this.isPc = !/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
		//
		this.normalLogin()
	},
	methods:{
		//解析url
		getUrlParam: function (name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
			var r = window.location.search.substr(1).match(reg)
			if (r != null)
				return unescape(r[2])
			return null
		},
		//正常登录
		normalLogin:function(){
			//
			let tempCode = this.getUrlParam("code")
			//获取code
			if(!tempCode){
				//回调连接
				let localUrl = encodeURIComponent(window.location.href) //回调url-本页
				//state
				let state = (new Date().getTime())+"expert"
				let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+global.suiteId+"&redirect_uri="+localUrl
				+"&response_type=code"
				+"&scope=snsapi_userinfo"
				+"&state="+state
				+"#wechat_redirect"
				window.location.href = url
			}else{
				let that = this
				//获取suite access token
				this.code = tempCode
				//从后台获取suite access token
				this.$ajax({
					method:"get",
					url:"/gateway/ed/workweixin/retreivesuiteaccesstoken"
				}).then(res=>{
					if(res.status=="200"&&res.data.retCode=="0"){
						that.suiteAccessToken = res.data.suite_access_token
						that.getUserInfo()
					}
				}).catch(err=>{
					console.log(err)					
				})
			}
		},
		//获取用户信息
		getUserInfo:function(){
			let that = this
			this.$ajax({
				method:"get",
				url:"/workweixinauth/cgi-bin/service/getuserinfo3rd",
				params:{
					"suite_access_token":this.suiteAccessToken,
					"code":this.code
				}
			}).then(res=>{
				if(res.status=="200"){
					if(res.data.errcode=="0"){
						//处理用户信息
						that.processUserInfo(res.data)
					}
				}
			}).catch(err=>{
				console.log(err)
			})
		},
		//处理用户
		processUserInfo:function(wwUserInfo){
			//读取当前用户信息
			let userData = {}
			//企业微信id
			userData.CorpId = wwUserInfo.CorpId
			//用户
			userData.UserId = wwUserInfo.UserId
			//open_userid
			userData.open_userid = wwUserInfo.open_userid
			//user_ticket
			userData.user_ticket = wwUserInfo.user_ticket
			//suite_id
			userData.suite_id = global.suiteId
			//存入缓存---workweixin_user
			this.$ls.set("expert_workweixin_user",userData)
			//后台查询
			this.$ajax({
				method:'post',
				url:'/gateway/ed/workweixin/login',
				data:userData
			}).then(res=>{
				if(res.status=="200"){
					//企业微信标志
					this.$ls.set("expert_workweixin_flag","1")
					if(res.data.retCode=="0"){
						//token
						this.$ls.set("expert_token",res.data.token)						
						//机构存在
						if(res.data.org){
							//org
							this.$ls.set("edCurrentOrg",res.data.org)
							//扩展信息
							if(res.data.external){
								this.$ls.set("edCurrentOrgExternal",res.data.external)
							}
							//增值服务
							if(res.data.openRecord){
								this.$ls.set("edOpenRecord",res.data.openRecord)
							}
							//当前用户信息
							this.$ls.set("edCurrentUser",res.data.edCurrentUser)
							//判断是否有昵称
							let nameValue = res.data.edCurrentUser.userExternal.name
							//////判断信息是否完整，若不完整则跳转完善信息页面
							let orgFinished = true
							//判断tb_ed_syde_edvas_external信息是否完整
							if(res.data.external){								
								//统一社会信用代码
								if(!(res.data.external.usccCode&&res.data.external.usccCode!='')){
									orgFinished = false
								}
								// //电话								
								// if(!(res.data.external.mobile&&res.data.external.mobile!='')){
								// 	orgFinished = false
								// }
								// //营业执照副本
								// if(!(res.data.external.busiPic&&res.data.external.busiPic!='')){
								// 	orgFinished = false
								// }
								// //身份证-正面
								// if(!(res.data.external.idPicFront&&res.data.external.idPicFront!='')){
								// 	orgFinished = false
								// }
								// //身份证-反面
								// if(!(res.data.external.idPicBack&&res.data.external.idPicBack!='')){
								// 	orgFinished = false
								// }
							}else{
								orgFinished = false
							}
							//判断机构信息是否完整
							// if(res.data.org){
							// 	//法人
							// 	if(!(res.data.org.legalPerson&&res.data.org.legalPerson!='')){
							// 		orgFinished = false
							// 	}
							// 	//电话
							// 	if(!(res.data.org.mobile&&res.data.org.mobile!='')){
							// 		orgFinished = false
							// 	}
							// }else{
							// 	orgFinished = false
							// }							
							//跳转home
							if(orgFinished){
								//PC
								if(this.isPc){
									window.location.href= global.pcUrl
								}else{
									this.$router.push({ name: "home" })
								}								
							}
							//如果未完善跳转完善页面
							else{								
								if(!this.isPc){
									this.$toast({
										message: "请用PC打开专家记，完善机构信息！",
										type: 'fail',
										duration: 5000
									})
									return
								}								
								//跳转完善页面
								this.$router.push({name:"orgfinish"})
							}														
						}else{
							if(res.data.invalidOrg){
							    //失效的操作员机构数据
							    this.$ls.set("invalidOrg",res.data.invalidOrg)
							}else{
							    this.$ls.remove('invalidOrg');
							}
							if(isPc){
							    window.location.href= global.pcUrl + "register"
							}else{
							    //跳转欢迎页
							    this.$router.push({ name: "index" })
							}
						}
					}else{
						if(res.data.retCode=="2"){
							this.$toast({
								message: "用户不存在！",
								type: 'fail',
								duration: 5000
							})
						}else if(res.data.retCode=="3"){
							this.$toast({
								message: "机构不存在！",
								type: 'fail',
								duration: 5000
							})
						}else{
							this.$toast({
								message: "系统出错，请稍候重试！",
								type: 'fail',
								duration: 5000
							})
						}
					}
				}else{
					this.$toast({
						message: "登录出错，请稍候重试！",
						type: 'fail',
						duration: 5000
					})
				}				
			})
		}
	}
}
</script>

<style>
</style>
